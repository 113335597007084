import { ConfigObject } from "libs"

const config: ConfigObject = {
	site: "present",
	includeLogoInPdf: true,
	userType: "present",
	baseGetAppsParams: {
		processedOnly: false,
		attrList: ["candidateName", "receivedTime", "identifier", "submittedBy", "duplication", "templateVersion"],
	},
	baseGetTemplatesParams: {
		attributes: ["templateName", "createdTime", "templateVersion", "lastModifiedTime", "live"],
	},
	createPresentRoleOnClients: true,
	homepage: "/candidates",
	captchaKey:
		process.env.REACT_APP_IS_TEST === "true" || process.env.REACT_APP_IS_DEV === "true"
			? "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
			: (process.env.REACT_APP_CAPTCHA_KEY ?? "6Ld5bpYeAAAAALSw49zzZAoaBvQGl9PKL8kzcGN_"),
	MAX_LOGO_SIZE: 1500000,
	localDefaultSettings: {
		recruiterPhoto: { initState: null, storageName: "recruiterPhoto", sessionOnly: false },
		recruiterName: { initState: null, storageName: "recruiterName", sessionOnly: false },
		companyName: { initState: null, storageName: "companyName", sessionOnly: false },
		recruiterEmail: { initState: null, storageName: "recruiterEmail", sessionOnly: false },
		recruiterPhone: { initState: null, storageName: "recruiterPhoneNumber", sessionOnly: false },
		jobTitle: { initState: "", storageName: "jobTitle", sessionOnly: false },
	},
	accessPermissions: {
		admin: {
			settings: "write",
			templates: "write",
			editing: "write",
			branding: "write",
		},
		recruiter: {
			settings: "read",
			templates: "read",
			editing: "write",
			branding: "none",
		},
		brandEditor: {
			settings: "read",
			templates: "write",
			editing: "write",
			branding: "write",
		},
	},
	storageKeys: {
		application: [
			//'totalExperience',
			"submittedBy",
			//'averageTenure',
			//'candidateName',
			//'emailAddress',
			"miscData",
			//'score',
			//'phoneNumber',
			"application_id",
			"fileTypes",
			"identifier",
			//'linkedUrl',
			"receivedTime",
			"duplication",
			"templateVersion",
		],
	},
	s3: {
		REGION: "eu-west-2",
		BUCKET: "logobucket-46e98a4c-0804-11eb-8223-48d705e1ba8b",
	},
	apiGateway: {
		NAME: "ara_db_api",
		REGION: "eu-west-2",
		URL: process.env.REACT_APP_API_URL || "https://bm95seyh19.execute-api.eu-west-2.amazonaws.com/Prod",
	},
	apiGatewayPublic: {
		NAME: "ara_db_public_api",
		REGION: "eu-west-2",
		URL: process.env.REACT_APP_PUBLIC_API_URL || "https://api.hireara.ai",
	},
	apiGatewayCustomPublic: {
		NAME: "ara_db_custom_public_api",
		REGION: "eu-west-2",
		URL: process.env.REACT_APP_CUSTOM_PUBLIC_API_URL,
	},
	apiGatewayLinks: {
		NAME: "ara_db_links_api",
		REGION: "eu-west-2",
		URL: process.env.REACT_APP_LINKS_API_URL,
	},
	cognito: {
		REGION: "eu-west-2",
		USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID || "eu-west-2_Kxohac48f",
		APP_CLIENT_ID: process.env.REACT_APP_USER_POOL_CLIENT_ID || "2d3mlunt9sga587kdj6lullio0",
		// IDENTITY_POOL_ID: "eu-west-2:e5b18e71-1983-4cf1-9914-6a63536d67fc"
	},
}

console.log("setting config")
global.config = config

export { config }
